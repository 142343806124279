import Network from '@/utils/Network'

const url1 = process.env.VUE_APP_API_URL
const url2 = process.env.VUE_APP_API_URL_V2

const getSports = (nIdFitArena) => {
  return Network.execute({
    secure: false,
    url: url1 + 'sports',
    params: {
      nIdFitArena: nIdFitArena
    }
  })
}

const getFitArenaList = () => {
  return Network.execute({
    secure: false,
    url: url1 + 'fitarena'
  })
}

const getFitArenaList2 = () => {
  return Network.execute({
    secure: false,
    url: url2 + 'fitarena'
  })
}

const getFitArena = (cle) => {
  return Network.execute({
    secure: false,
    url: url1 + 'fitarena/detail',
    params: {
      sCle: cle
    }
  })
}

const getFitArena2 = (cle) => {
  return Network.execute({
    secure: false,
    url: url2 + 'fitarena/detail',
    params: {
      sCle: cle
    }
  })
}

export { getSports, getFitArenaList, getFitArena, getFitArenaList2, getFitArena2 }
