<template>
  <div
    class="border-card rounded-lg bg lg:mx-4 flex flex-col justify-between mb-6"
  >
    <div class="px-4 py-1">
      <DoiText :textColor="0" :fontSize="4" :fontFamily="6" class="uppercase">{{
        fitArena.libelleSalle.slice(0, 20) +
        (fitArena.libelleSalle.length > 20 ? "..." : "")
      }}</DoiText>
    </div>
    <div class="divider" />
    <div class="pl-4 pt-6 pb-2 flex space-x-6">
      <img src="@/assets/img/localisation.svg" class="w-3" />
      <DoiText :textColor="1" :fontSize="3" :fontFamily="10">{{
        fitArena.legende
      }}</DoiText>
    </div>
    <div class="flex items-center sports lg:-ml-3 lg:-mr-3">
      <div
        v-for="(sport, _, i) in fitArena.sports"
        :key="`${sport}-${i}`"
        v-if="i <= 3"
        class="text-center"
      >
        <SportSelectorItemHomePage :sport="sport" />
      </div>
    </div>
    <template
      v-if="Object.keys(fitArena.sports).length > 4"
      class="pl-4 mx-auto mb-6 w-7/12 rounded-lg h-10"
    >
      <button
        @click="goToFitArena()"
        class="rounded-full border-button text-xs mx-auto mb-6"
      >
        Découvrez les autres sports
      </button>
    </template>
    <div v-else class="pl-4 mx-auto mb-6 w-7/12 rounded-lg h-10" />
    <img
      @click="goToFitArena()"
      class="img-size cursor-pointer"
      :src="fitarenaImg"
    />
    <div
      @click="goToFitArena()"
      class="bg-red-600 text-center py-2 rounded-b-lg cursor-pointer"
    >
      <DoiText :textColor="2" :font-size="3">RÉSERVER</DoiText>
    </div>
  </div>
</template>

<script>
import SportSelectorItemHomePage from "@/views/fitarena/SportSelectorItemHomePage";

import { generateImageUrlV2 } from "@/utils/imgUrlBuilder";

export default {
  name: "FitArenaCard",
  props: {
    fitArena: Object,
  },
  components: {
    SportSelectorItemHomePage,
  },
  computed: {
    fitArenaRoute() {
      if (this.fitArena && this.fitArena.cle)
        return "/fit-arena/" + this.fitArena.cle;
      return "";
    },
    fitarenaImg() {
      if (this.fitArena && this.fitArena.urlEcusson)
        return generateImageUrlV2(this.fitArena.urlEcusson);
      else return require("@/assets/img/fitarena/fitarena-bellevue.png");
    },
  },
  methods: {
    goToFitArena() {
      localStorage.setItem("idFA", this.fitArena.idFitArena);
      this.$router.push(`/fit-arena/${this.fitArena.cle}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.img-size {
  width: 350px;
  height: 245px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.border-card {
  border: 1px solid $doi-bg-gray-color;
}

.bg {
  background-color: #fcfcfc;
  width: 350px;
}

.divider {
  width: 100%;
  height: 2px;
  color: $doi--color-color0;
}

.border-button {
  border: 2px solid $doi-bg-gray-color;
  background-color: #fff;
  padding: 8px 14px;
  color: $doi--text-color3;
  font-family: $doi--text-font-family5;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.sports {
  padding-bottom: 15px;
  padding-left: 16px;
}
</style>
