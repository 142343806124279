import { getUserInfo, setUserInfo } from "@/api/UserService";
import Vue from "vue";

const initState = () => {
  return {
    isFetched: false,
    isFetching: false,
    data: {
      userId: null,
      userMail: null,
      nom: null,
      prenom: null,
      identifiant: null,
      portable: null,
      emailing: null,
      pmr: null,
      mineur: null,
      avatar: null,
    },
  };
};

export default {
  namespaced: true,
  namespace: "user",
  state: initState(),
  actions: {
    SET_INFO({ commit, dispatch }, userInfo) {
      commit("SET_INFO", userInfo.userId);
      dispatch("GET_PROFIL_INFO");
    },
    RESET_INFO({ commit }) {
      commit("RESET_INFO");
    },
    async GET_PROFIL_INFO({ commit, state }) {
      if (!state.isFetched && !state.isFetching) {
        try {
          commit("setIsFetchingTrue");
          const result = await getUserInfo(state.data.userId);

          if (result) {
            commit("SET_PROFIL_INFO", {
              userMail: result.email,
              nom: result.nom,
              prenom: result.prenom,
              identifiant: result.identifiant,
              portable: result.portable,
              emailing: result.emailingCollectivite,
              pmr: result.equipementAdapte,
              mineur: result.mineur,
              avatar: result.avatarUrl,
            });
            commit("setFetchFinish");
          } else {
            commit("setFetchError");
          }
        } catch (err) {
          commit("setFetchError");
        }
      }
    },
    async SET_PROFIL_INFO({ commit, _, dispatch }, payload) {
      const response = await setUserInfo({
        sNom: payload.sNom,
        sPrenom: payload.sPrenom,
        sPortable: payload.sPortable,
        sEmail: payload.sEmail,
        sIdentifiant: payload.sIdentifiant,
        rAvatar: payload.rAvatar,
        nEmailing: payload.nEmailing,
      });
      if (response && response.bSucces) {
        commit("setFetchedDirty");
        await dispatch("GET_PROFIL_INFO");
        const message = "Vos informations ont bien été mises à jour";
        if (screen.width > 640) {
          Vue.$toast.success(message);
        } else {
          Vue.$toast.success(message, {
            position: "top",
          });
        }
        return response;
      } else {
        if (
          response.aDoublon &&
          Array.isArray(response.aDoublon) &&
          response.aDoublon.length > 0
        ) {
          const doublon = response.aDoublon.shift();
          const message = `L'information du champ ${doublon} est déjà utilisée`;
          if (screen.width > 640) {
            Vue.$toast.error(message);
          } else {
            Vue.$toast.error(message, {
              position: "top",
            });
          }
        } else {
          const message =
            "Une erreur est survenue lors de la mise à jour de vos données";
          if (screen.width > 640) {
            Vue.$toast.error(message);
          } else {
            Vue.$toast.error(message, {
              position: "top",
            });
          }
        }
        throw response;
      }
    },
    SET_FIT_ARENA_LISTE_NOIRE({ commit }, fitArenaListeNoire) {
      commit("setFitArenaListeNoire", fitArenaListeNoire);
    },
  },
  mutations: {
    SET_INFO: (state, userId) => {
      state.data.userId = userId;
    },
    SET_PHONE: (state, phone) => {
      state.data.portable = phone;
    },
    RESET_INFO: (state) => {
      Object.assign(state, initState());
    },
    SET_PROFIL_INFO: (state, payload) => {
      state.data.userMail = payload.userMail;
      state.data.nom = payload.nom;
      state.data.prenom = payload.prenom;
      state.data.identifiant = payload.identifiant;
      state.data.portable = payload.portable;
      state.data.emailing = payload.emailing;
      state.data.pmr = payload.pmr;
      state.data.mineur = payload.mineur;
      state.data.avatar = payload.avatar;
      state.data = Object.assign({}, state.data);
    },
    setFetchError: (state) => {
      state.isFetched = false;
      state.isFetching = false;
    },
    setFetchFinish: (state) => {
      state.isFetched = true;
      state.isFetching = false;
    },
    setIsFetchingTrue: (state) => {
      state.isFetching = true;
    },
    setFetchedDirty: (state) => {
      state.isFetched = false;
      state.isFetching = false;
    },
    setFitArenaListeNoire: (state, payload) => {
      state.data.fitArenaListeNoire = payload;
    },
  },
  getters: {
    user_fetched: (state) => {
      return state.isFetched;
    },
    get_user: (state) => {
      return state.data;
    },
    get_user_id: (state) => {
      return state.data.userId;
    },
    get_user_mail: (state) => {
      return state.data.userMail;
    },
    get_user_phone: (state) => {
      return state.data.portable;
    },
  },
};
