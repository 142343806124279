<template>
  <div id="app" class="overflow-x-hidden">
    <component :is="layout">
      <router-view />
    </component>
    <DoiCookiesNavbar />
  </div>
</template>

<script>
const defaultLayout = "blank";
export default {
  data() {
    return {
      openOverlay: false,
      toolbarInterval: "",
    };
  },
  created() {
    this.$store.dispatch("AuthModule/LOAD_TOKEN");
  },
  mounted() {
    if (
      process.env.VUE_APP_PROD ||
      process.env.VUE_APP_CORDOVA ||
      process.env.VUE_APP_COMMERCIAL
    ) {
      this.toolbarInterval = setInterval(() => {
        if (document.getElementById("krtoolbar")) {
          this.debugToolbar();
        }
      }, 100);
    }
  },
  computed: {
    layout() {
      return this.$route.meta.layout || defaultLayout;
    },
    getToken() {
      return this.$store.getters["AuthModule/get_token"];
    },
  },
  methods: {
    debugToolbar() {
      document.getElementById("krtoolbar").style.display = "none";
      clearInterval(this.toolbarInterval);
    },
  },
  watch: {
    getToken: {
      async handler(token) {
        if (token !== null && token !== "")
          this.$store.dispatch("AuthModule/LOAD_TOKEN");
        else this.$store.dispatch("AuthModule/DECONNEXION");
      },
    },
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 1s ease;
}

.slide-fade-leave-active {
  transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}

#app {
  height: 100%;
}
</style>
