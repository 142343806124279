<template>
  <div
    class="bg-none lg:bg-fitarena-background background bg-no-repeat bg-right-top flex flex-col justify-start"
  >
    <div v-if="fitArenaList.length > 0" class="mx-auto">
      <div class="my-10 flex flex-wrap justify-center">
        <template v-for="fitArena in fitArenaList">
          <FitArenaCard
            v-if="fitArena.active"
            :key="`fa-${fitArena.idFitArena}`"
            :fitArena="fitArena"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import FitArenaCard from "@/views/holding/components/FitArenaCard";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FitArenaList",
  data() {
    return {
      finalFitArenaList: [],
    };
  },
  components: {
    FitArenaCard,
  },
  mounted() {
    this.retrieveFitarenas();
  },
  computed: {
    ...mapGetters("FitArenaListModule", ["fitArenaList"]),
    fitArenaListeNoire() {
      try {
        return JSON.parse(localStorage.getItem("fitArenaListeNoire"));
      } catch (e) {
        return [];
      }
    },
    finalList() {
      this.finalFitArenaList = this.fitArenaList;
      for (let i = 0; i < this.finalFitArenaList.length; i++) {
        for (let x = 0; x < this.fitArenaListeNoire.length; x++) {
          if (
            this.finalFitArenaList[i].idFitArena ===
            this.fitArenaListeNoire[x].id_fit_arena
          ) {
            this.finalFitArenaList.splice(i, 1);
          }
        }
      }
      return this.finalFitArenaList;
    },
  },
  methods: {
    ...mapActions("FitArenaListModule", ["retrieveFitarenas"]),
  },
};
</script>

<style scoped>
.background {
  min-height: 400px;
}
</style>
