import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";

import store from "@/store"; // import du gestionnaire d'état global
import routes from "@/router/route";

Vue.use(Router);
Vue.use(Meta);

let mode = "history";

if (window.cordova) {
  mode = "hash";
}

const router = new Router({
  mode,
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("fa-token-v2");

  if (to.fullPath !== "/signin" && to.path !== "/reset-password") {
    if (to.meta.secure && (token === null || token === ""))
      return router.push("/signin");
  }
  if (to.fullPath === "/signin" || to.fullPath === "/signup") {
    if (token !== null && token !== "") return router.push("/profile");
  }

  store.dispatch("RouterHistoryModule/setLastRoute", from, { root: true });

  const sMetaTitle = "Fit Arena | Book, Play, Share";

  document.title = sMetaTitle;

  next();
});

export default router;
