<template>
  <div class="mx-4">
    <DoiHeader class="title" :level="2" :headerColor="1" :fontFamily="0"
      >COMMENT ÇA FONCTIONNE ?</DoiHeader
    >

    <div class="flex justify-center">
      <img
        src="https://www.smc2-construction.com/wp-content/uploads/2022/11/1-2-3.svg"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BookPresentation",
};
</script>

<style lang="scss">
@import "~@/styles/_variables";

@media (max-width: $doi-breakpoint-mobile) {
  .title {
    font-size: 1.5rem;
  }
}
</style>
