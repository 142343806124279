import Token from "@/utils/Token";
import router from "@/router";

export default {
  namespaced: true,
  namespace: "auth",
  state: {
    token2: localStorage.getItem("fa-token-v2"),
  },
  actions: {
    SET_TOKEN({ commit }, token) {
      if (token !== null && token !== undefined && token !== "") {
        localStorage.setItem("fa-token-v2", token);
        commit("SET_TOKEN", token);
      }
    },
    async LOAD_TOKEN({ state, commit, dispatch }) {
      const token = localStorage.getItem("fa-token-v2");
      if (token !== null && token !== undefined && token !== "") {
        const userInfo = Token.parseJwt(token);
        const onJuneTwentySix = 1719405300;
        const iat = userInfo.iat;
        if (iat < onJuneTwentySix) {
          // L'UTILISATEUR S'EST CONNECTÉ AVANT LE 26 JUIN 2024 A 14H35
          await dispatch("AuthModule/DECONNEXION"); // FORCER UNE DÉCONNEXION
          return;
        }

        if (token !== state.token2) commit("SET_TOKEN", token);
        await dispatch(
          "UserModule/SET_INFO",
          {
            userId: parseInt(userInfo.id),
            contactId: parseInt(userInfo.id),
          },
          { root: true }
        );

        await dispatch("UserModule/GET_PROFIL_INFO", null, { root: true });
        dispatch("InvitationModule/valideInvitationPostCo", null, {
          root: true,
        });
        dispatch("CartModule/INIT_CART2", null, { root: true });
      }
      return token;
    },
    RESET_TOKEN({ commit }) {
      commit("RESET_TOKEN");
    },
    async DECONNEXION({ dispatch }) {
      dispatch("RESET_TOKEN");
      dispatch("UserModule/RESET_INFO", null, { root: true });
      dispatch("CartModule/resetInfo", null, { root: true });
      dispatch("PaymentModule/resetStore", null, { root: true });
      dispatch("InvitationModule/removeInvitation", null, { root: true });
      dispatch("ContactListModule/resetStore", null, { root: true });
      router.push("/signin");
    },
  },
  getters: {
    is_connected: (state) => {
      return state.token2 && state.token2 !== "";
    },
    get_token_2: (state) => {
      const token2 = localStorage.getItem("fa-token-v2");
      if (token2 !== state.token2) window.location.reload();
      return state.token2;
    },
    get_userIdV2: (state) => {
      return JSON.parse(atob(state.token2.split(".")[1])).id;
    },
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token2 = token;
    },
    RESET_TOKEN: (state) => {
      localStorage.removeItem("fa-token-v2");
      state.token2 = localStorage.getItem("fa-token-v2");
    },
  },
};
