<template>
  <span class="flex flex-col">
    <div
      class="w-16 h-16 bg-white items-center rounded-lg shadow-sport mt-3 mr-2 p-2 border-2 border-white"
    >
      <img v-if="sport.icone" :src="sportWithIcone" />
      <img v-else :src="require(`@/assets/icons/utils/sports/${sportIcon}`)" />
    </div>
    <DoiText
      :textColor="3"
      :fontSize="0"
      :fontFamily="5"
      :class="isSelected ? 'red' : ''"
      class="mt-1 tracking-wider font"
      >{{ sport.libelle }}
    </DoiText>
  </span>
</template>

<script>
import sports from "@/data/sports.json";
import { generateImageUrlV2 } from "@/utils/imgUrlBuilder";

export default {
  name: "SportSelectorItem",
  props: {
    sport: Object,
    isSelected: Boolean,
  },
  computed: {
    sportIcon() {
      let icon = "";
      const sp = sports.find(
        (sport) => sport.label === this.sport.libelle.toLowerCase().trim()
      );
      if (sp !== undefined) icon = sp.icon;
      else icon = "Fitness.svg";
      return icon;
    },
    sportWithIcone() {
      return generateImageUrlV2(this.sport.icone);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.red {
  color: $doi--color-color0;
}

.shadow-sport {
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1),
    0 10px 10px 0px rgba(0, 0, 0, 0.02);
}

.font {
  font-size: 0.6rem;
}
</style>
