<template>
  <div>
    <div class="container mx-auto">
      <section class="mt-6" id="fit-arena-list">
        <div class="text-center mx-4">
          <DoiHeader class="title" :level="1" :headerColor="1" :fontFamily="1"
            >JE RÉSERVE</DoiHeader
          >
          <DoiHeader class="title" :level="1" :headerColor="1" :fontFamily="1"
            >MON CRÉNEAU SPORTIF</DoiHeader
          >
        </div>
        <FitArenaList />
      </section>
      <section>
        <BookPresentation />
      </section>
      <section class="mt-6" id="sport">
        <div class="mx-4">
          <DoiHeader
            class="title-sport"
            :level="1"
            :headerColor="1"
            :fontFamily="0"
            >LES SPORTS DISPONIBLES AVEC FIT ARENA</DoiHeader
          >
        </div>
        <SportList />
      </section>
    </div>
    <footer>
      <ShareSection />
    </footer>
  </div>
</template>

<script>
import SportList from "./components/SportList";
import FitArenaList from "./components/FitArenaList";
import Header from "./components/Header";
import ShareSection from "@/views/share/ShareSection";
import BookPresentation from "./components/BookPresentation";
import PlayPresentation from "./components/PlayPresentation";
import SharePresentation from "./components/SharePresentation";

export default {
  name: "FitArenaHolding",
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Réservation de salle multisports connectée, intelligente et autonome. Partage d'expériences sportives",
      },
    ],
    link: [{ rel: "canonical", href: "https://www.fit-arena.fr/" }],
  },
  components: {
    SportList,
    FitArenaList,
    Header,
    ShareSection,
    BookPresentation,
    PlayPresentation,
    SharePresentation,
  },
  computed: {
    isMobile() {
      return window.navigator.userAgent.includes("cordova");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.line {
  width: 80%;
}

@media (max-width: $doi-breakpoint-mobile) {
  .title {
    font-size: $doi--header5;
  }
  .title-sport {
    font-size: 1.5rem;
  }
}
</style>
