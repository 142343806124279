import Network from "@/utils/Network";

const url1 = process.env.VUE_APP_API_URL;
const url2 = process.env.VUE_APP_API_URL_V2;

const getReservation = (userId, aTypeReservation) => {
  return Network.execute({
    method: "POST",
    url: url1 + `reservations?nIdGrandPublic=${userId}&sTemporalite=futur`,
    payload: {
      aTypeReservation,
    },
  });
};

const getReservationDetail = (idReservation) => {
  return Network.execute({
    method: "GET",
    url: url1 + `reservation-detail?sIdReservation=${idReservation}`,
  });
};

const getGroupeInvitation = (params) => {
  return Network.execute({
    method: "GET",
    url: url1 + "reservations/groupe/invitation",
    params,
  });
};

const getGroupeInvitationV2 = (reservation, invitation) => {
  return Network.execute({
    method: "GET",
    url:
      url2 +
      `reservations/groupe/invitation?reservation=${reservation}&invitation=${invitation}`,
  });
};

const getReservationGroupe = (params) => {
  return Network.execute({
    method: "GET",
    url: url1 + "reservations/groupe",
    params,
  });
};

const generateQrCodeV2 = (nIdContact, sIdReservation, type = "participant") => {
  let url;
  if (type !== "seance") {
    url = url2 + "qrcode/" + type + "/" + nIdContact + "/" + sIdReservation;
  } else {
    url = url2 + "qrcode/seance/" + sIdReservation;
  }

  return Network.execute({
    method: "GET",
    url: url,
  });
};

const generateQrCode = (
  nIdContact,
  sIdReservation,
  sVersion,
  sModeAdherent
) => {
  return Network.execute({
    method: "POST",
    url: url1 + "reservations/generer-qrcode",
    payload: {
      nIdContact: nIdContact,
      sIdReservation: sIdReservation,
      sVersion: sVersion,
      sModeAdherent: sModeAdherent,
    },
  });
};

const deleteParticipant = (idResa, idInvite) => {
  return Network.execute({
    method: "DELETE",
    url: url1 + "reservation/suppression",
    payload: {
      nIdInvite: idInvite,
      sIdReservation: idResa,
    },
  });
};

const setChangeResponse = ({
  sIdReservation,
  nIdGrandPublic,
  nIdOrganisateur,
  sJetonInvitation,
  sReponse,
  sMd5ActivationOrganisateur,
}) => {
  return Network.execute({
    method: "POST",
    url:
      url1 +
      `reservations/invitation/change-reponse?sIdReservation=${sIdReservation}&nIdGrandPublic=${nIdGrandPublic}&nIdOrganisateur=${nIdOrganisateur}&sJetonInvitation=${sJetonInvitation}&sReponse=${sReponse}&sMd5ActivationOrganisateur=${sMd5ActivationOrganisateur}`,
    payload: {},
  });
};

const setAllowSharingCode = ({
  sIdReservation,
  nIdOrganisateur,
  sDecision,
}) => {
  return Network.execute({
    method: "POST",
    url:
      url1 +
      `reservations/autorisation-diffusion?sIdReservation=${sIdReservation}&nIdOrganisateur=${nIdOrganisateur}&sDecision=${sDecision}`,
    payload: {},
  });
};

const setRequestAdaptedEquipment = ({ sIdReservation, bEquipementAdapte }) => {
  return Network.execute({
    method: "PUT",
    url: url1 + "reservations/equipement-adapte",
    payload: {
      sIdReservation: sIdReservation,
      bEquipementAdapte: bEquipementAdapte ? 1 : 0,
    },
  });
};

const getUserAlreadyPlayed = (nIdContact) => {
  return Network.execute({
    method: "GET",
    url: url1 + "reservations/liste-equipiers-parties-deja-jouees",
    params: {
      nIdContact: nIdContact,
    },
  });
};

const getUserAlreadyPlayedV2 = () => {
  return Network.execute({
    method: "GET",
    url: url2 + "anciens_equipiers",
  });
};

const sendInvitation = (aIdContact, sIdReservation, nIdGrandPublic) => {
  return Network.execute({
    method: "POST",
    url: url1 + "grand-public/invite-ancien-equipier",
    payload: {
      aIdContact: aIdContact,
      sIdReservation: sIdReservation,
      nIdGrandPublic: nIdGrandPublic,
    },
  });
};

const sendInvitationV2 = (params) => {
  return Network.execute({
    method: "POST",
    url: url2 + "invitation/ancien_equipier",
    payload: params,
  });
};

const getInvitationReservation = ({
  sIdReservation,
  bRestreindreAuxInvites,
  sJetonInvitation,
}) => {
  bRestreindreAuxInvites = bRestreindreAuxInvites === true ? 1 : 0;
  return Network.execute({
    method: "GET",
    url:
      url1 +
      `reservations/invitation?sIdReservation=${sIdReservation}&bRestreindreAuxInvites=${bRestreindreAuxInvites}&sJetonInvitation=${sJetonInvitation}`,
  });
};

const valideInvitation = (payload) => {
  return Network.execute({
    method: "POST",
    url: url1 + "reservations/invitation/reponse",
    payload: payload,
  });
};

const valideInvitationV2 = (payload) => {
  return Network.execute({
    method: "POST",
    url: url2 + "invitation/reponse",
    payload: payload,
  });
};

const launchDemo = (payload) => {
  return Network.execute({
    method: "POST",
    url: url1 + "reservation/demo/demarre",
    payload: payload,
  });
};

const getDetailReservation = (sIdReservation) => {
  return Network.execute({
    method: "GET",
    url: url1 + "reservation-detail",
    params: {
      sIdReservation,
    },
  });
};

const getContact = (nIdUtilisateur, nIdContact) => {
  return Network.execute({
    method: "GET",
    url: url1 + `Contact/${nIdContact !== undefined ? nIdContact : ""}`,
    params: {
      nIdUtilisateur: nIdUtilisateur,
    },
  });
};

const deleteReservation = (uuidReservation) => {
  return Network.execute({
    method: "PUT",
    url: url1 + "reservation/annulation",
    payload: {
      uuidReservation: uuidReservation,
    },
    secure: true,
  });
};

const deleteReservationV2 = (uuidReservation) => {
  return Network.execute({
    method: "PUT",
    url: url2 + `reservation/annulation/gp/${uuidReservation}`,
  });
};

const getUuidInvitationV2 = (idReservation) => {
  return Network.execute({
    method: "GET",
    url: url2 + `jeton_invitation/${idReservation}`,
  });
};

const shareReservationV2 = (uuidReservation, diffusionAutorisee) => {
  return Network.execute({
    method: "POST",
    secure: true,
    url: url2 + "partage_lien_invitation",
    payload: {
      uuidReservation,
      diffusionAutorisee,
    },
  });
};

export {
  getReservation,
  getReservationDetail,
  getGroupeInvitation,
  getReservationGroupe,
  generateQrCode,
  deleteParticipant,
  setChangeResponse,
  setAllowSharingCode,
  setRequestAdaptedEquipment,
  getUserAlreadyPlayed,
  sendInvitation,
  getInvitationReservation,
  valideInvitation,
  valideInvitationV2,
  launchDemo,
  getDetailReservation,
  getContact,
  deleteReservation,
  deleteReservationV2,
  getGroupeInvitationV2,
  sendInvitationV2,
  getUserAlreadyPlayedV2,
  getUuidInvitationV2,
  shareReservationV2,
  generateQrCodeV2,
};
