<template>
  <div class="flex flex-col">
    <div class="p-4 flex items-center gap-2">
      <!--  AFFICHAGE DE LA ZONE RÉSERVÉE -->
      <DoiText :textColor="1" :fontFamily="1"
        >{{
          bookingDetail.zone.includes(",") ? "ZONES RÉSERVÉES" : "ZONE RÉSERVÉE"
        }}
        :</DoiText
      >
      <DoiText :textColor="1" :fontFamily="0">{{ bookingDetail.zone }}</DoiText>
    </div>
    <div
      v-if="bookingDetail.reservationType !== 'Grand public'"
      class="pb-4 px-4 flex items-center gap-2"
    >
      <!--  AFFICHAGE DE L'ORGANISME -->
      <DoiText :textColor="1" :fontFamily="1">ORGANISME :</DoiText>
      <DoiText :textColor="1" :fontFamily="0">{{
        bookingDetail.organisme
      }}</DoiText>
    </div>
    <div class="flex flex-col lg:flex-row p-4">
      <div class="w-full lg:w-5/12 lg:border-r lg:border-black flex flex-col">
        <div class="flex-flex-col justify-between">
          <div class="flex justify-center">
            <DoiText :fontFamily="1" :fontSize="4" :textColor="1"
              >Mes accès</DoiText
            >
          </div>
          <div class="flex items-center justify-center mt-2">
            <DoiImage
              :class="userAvatarBorderClass"
              size="7rem"
              :rounded="true"
              :src="userAvatar"
            />
          </div>
          <div class="flex mt-4 flex-col items-center justify-center">
            <DoiText :textColor="3" :fontSize="3"
              >{{ user.firstname }} {{ user.lastname }}</DoiText
            >
            <DoiText
              :textColor="0"
              :fontFamily="5"
              :fontSize="3"
              class="info"
              >{{ user.identifier }}</DoiText
            >
            <DoiText v-if="isOrganizer" :textColor="0" :fontFamily="4"
              >Organisateur</DoiText
            >
          </div>
          <div class="flex justify-center mt-4">
            <div
              v-if="
                bookingDetail.reservationType == 'Animateur' ||
                bookingDetail.reservationType == 'Organisme'
              "
            >
              <DoiButton
                class="mb-4"
                @click="generateQrCodeAnimateur(get_userIdV2)"
                :bgColor="6"
                :fontFamily="5"
                >QR CODE ANIMATEUR
              </DoiButton>
              <DoiButton
                @click="generateQrCodeAdherent(get_userIdV2)"
                :bgColor="6"
                :fontFamily="5"
                >QR CODE SÉANCE
              </DoiButton>
            </div>
            <div v-else-if="bookingDetail.reservationType == 'Seance'">
              <DoiButton
                v-if="user.response === 2"
                @click="generateQrCode(get_userIdV2)"
                :bgColor="6"
                :fontFamily="5"
                >QR CODE ENTRÉE / SORTIE</DoiButton
              >
            </div>
            <div v-else>
              <DoiButton
                v-if="user.response === 2"
                @click="generateQrCode(get_userIdV2)"
                :bgColor="6"
                :fontFamily="5"
                >QR CODE ENTRÉE / SORTIE</DoiButton
              >
            </div>

            <div v-if="user.response === 3" class="bg-red py-3 px-4">
              <DoiText :fontFamily="5">INVITATION DÉCLINÉE</DoiText>
            </div>
            <div v-if="user.response === 1" class="bg-grey py-3 px-4">
              <DoiText :fontFamily="5">EN ATTENTE DE RÉPONSE</DoiText>
            </div>
          </div>
        </div>
        <div class="mt-8 lg:px-4">
          <div
            v-for="(membre, nIndex) in familles"
            class="flex items-center py-1 justify-between"
            :key="nIndex"
          >
            <BookingItemDetailFamily
              @generateQrCode="generateQrCode"
              :nIndex="nIndex"
              :member="membre"
            />
          </div>
        </div>
      </div>
      <div
        class="w-full lg:w-7/12 lg:mt-0"
        :class="{
          'mt-6': familles.length !== 0,
          '-mt-4': familles.length == 0,
        }"
      >
        <div class="flex flex-col">
          <div class="flex justify-center">
            <DoiText
              v-if="
                bookingDetail.reservationType === 'Grand public' &&
                bookingDetail &&
                invites &&
                invites.length > 0
              "
              :fontFamily="1"
              :fontSize="4"
              :textColor="1"
              >Autres participants</DoiText
            >
          </div>
          <div v-if="bookingDetail" class="mt-2 lg:pl-4">
            <div
              v-for="(invite, nIndex) in invites"
              class="flex items-center py-1 justify-between"
              :key="nIndex"
            >
              <BookingItemDetailParticipant
                @idContactParticipant="idContactParticipant"
                @generateQrCode="generateQrCode"
                :nIndex="nIndex"
                :bookingDetail="bookingDetail"
                :isOrganizer="isOrganizer"
                :participant="invite"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="bookingDetail.uuidReservation" class="flex items-center p-4">
      <DoiText :fontFamily="1" :fontSize="2" :textColor="1"
        >UUID RÉSERVATION :
        <DoiText type="span" :fontFamily="0" :textColor="1">{{
          bookingDetail.uuidReservation
        }}</DoiText></DoiText
      >
    </div>
    <div
      class="bg-fa-light-gray p-4 flex flex-col lg:flex-row justify-start"
      v-if="bookingDetail.reservationType === 'Grand public'"
    >
      <button
        v-if="isOrganizer || isSharing"
        class="button red icon w-full lg:w-4/12 mr-0 lg:mr-6 ml-0 mb-2 lg:ml-6 lg:mb-0 lg:mt-0"
        @click="inviter()"
      >
        <DoiText type="span" :fontFamily="6">INVITER DES JOUEURS</DoiText>
        <img src="@/assets/icons/utils/compte/person.svg" />
      </button>
      <router-link
        v-if="modifyTimeResponse <= -900"
        class="button red icon w-full lg:w-4/12 mr-0 lg:mr-6 ml-0 mb-2 lg:mb-0 lg:mt-0"
        :to="`/invitation/${bookingDetail.uuidReservation}/${bookingDetail.uuidToken}`"
      >
        <DoiText type="span" :fontFamily="6">MODIFIER MA RÉPONSE</DoiText>
        <img src="@/assets/icons/utils/compte/edit_white.svg" />
      </router-link>
      <button
        v-if="isOrganizer && bookingDetail.reservationCancellation"
        class="button red icon w-full lg:w-4/12 mr-0 lg:mr-6 ml-0 lg:mb-0 lg:mt-0"
        @click="annulationReservation = true"
      >
        <DoiText type="span" :fontFamily="6">ANNULER MA RÉSERVATION</DoiText>
        <span class="material-icons">delete_outline</span>
      </button>
    </div>

    <Modal
      title="INVITER DES JOUEURS"
      :open="openInvitGamer"
      :headerIcon="require('@/assets/icons/utils/compte/person.svg')"
      size="sm"
      :keepratio="true"
      @close-modal="openInvitGamer = false"
    >
      <template slot="modal_content">
        <BookingGamer
          @close="closeModal"
          :booking="bookingDetail"
          :isOrganizer="isOrganizer"
        />
      </template>
    </Modal>

    <Modal
      title="MON CODE D'ENTRÉE"
      :open="showQrCode"
      :keepratio="true"
      size="sm"
      @close-modal="showQrCode = false"
    >
      <template slot="modal_content">
        <div class="overflow-hidden">
          <div
            class="flex justify-between items-center cursor-pointer tab"
            @click="qrCodeSectionOpen = !qrCodeSectionOpen"
          >
            <div class="flex flex-col">
              <DoiText
                :textColor="3"
                :fontSize="3"
                :fontFamily="6"
                class="accordion-title"
                >ACCÉDER À LA FIT ARENA</DoiText
              >
            </div>
            <div class="bg-fa-red flex items-center text-white p-2 w-12 h-12">
              <span class="material-icons text-3xl" v-if="!qrCodeSectionOpen">
                keyboard_arrow_down
              </span>
              <span class="material-icons text-3xl" v-if="qrCodeSectionOpen">
                keyboard_arrow_up
              </span>
            </div>
          </div>
          <div v-show="qrCodeSectionOpen" class="tab-content">
            <div class="flex flex-col py-4">
              <div
                class="flex flex-row bg-black text-white items-center justify-center"
              >
                <svg width="26" height="26" viewBox="0 0 32 32">
                  <path
                    fill="currentColor"
                    d="M16 4C9.383 4 4 9.383 4 16s5.383 12 12 12s12-5.383 12-12S22.617 4 16 4zm0 2c5.535 0 10 4.465 10 10s-4.465 10-10 10S6 21.535 6 16S10.465 6 16 6zm-1 4v8h2v-8zm0 10v2h2v-2z"
                  />
                </svg>
                <span class="ml-2">Chaque QR code est individuel</span>
              </div>
              <div v-if="qrCode !== ''">
                <img
                  :width="imgSize"
                  class="mx-auto qrcode"
                  :src="qrCode"
                  alt="qrcode"
                />

                <div v-if="codePin" class="flex flex-col text-center mt-5">
                  <span
                    >Si vous rencontrez des difficultés à scanner votre QR code,
                  </span>
                  <span
                    >vous pouvez également saisir votre code PIN pour participer
                    à votre séance.</span
                  >
                  <span class="text-semibold text-2xl">{{ codePin }}</span>
                </div>
              </div>
              <div v-else>
                <DoiText
                  :textColor="3"
                  :fontSize="3"
                  :fontFamily="6"
                  class="text-center"
                  >Chargement ...</DoiText
                >
              </div>
            </div>
          </div>
          <div v-if="bookingDetail.reservationType != 'Animateur'">
            <div
              class="flex justify-between mt-4 cursor-pointer tab"
              @click="howTo = !howTo"
            >
              <div class="flex flex-col justify-center">
                <div class="flex flex-row">
                  <DoiText
                    :textColor="3"
                    :fontSize="3"
                    :fontFamily="6"
                    class="accordion-title"
                    >COMMENT VA SE PASSER MA SÉANCE ?</DoiText
                  >
                </div>
              </div>
              <div class="bg-fa-red flex items-center text-white p-2 w-12 h-12">
                <span class="material-icons text-3xl" v-if="!howTo">
                  keyboard_arrow_down
                </span>
                <span class="material-icons text-3xl" v-if="howTo">
                  keyboard_arrow_up
                </span>
              </div>
            </div>
            <div v-show="howTo" class="tab-content">
              <div class="flex flex-col py-4 pl-2">
                <DoiText :textColor="3" :fontFamily="4" :fontSize="3"
                  ><DoiText
                    type="span"
                    :textColor="0"
                    :fontFamily="5"
                    :fontSize="3"
                    >1 - </DoiText
                  >Je me présente devant la Fit Arena, je passe mon QR Code
                  devant le lecteur et je rentre dans la salle</DoiText
                >
                <DoiText :textColor="3" :fontFamily="4" :fontSize="3"
                  ><DoiText
                    type="span"
                    :textColor="0"
                    :fontFamily="5"
                    :fontSize="3"
                    >2 - </DoiText
                  >J'accède aux vestiaires, je me change</DoiText
                >
                <DoiText :textColor="3" :fontFamily="4" :fontSize="3"
                  ><DoiText
                    type="span"
                    :textColor="0"
                    :fontFamily="5"
                    :fontSize="3"
                    >3 - </DoiText
                  >J'attends que l'équipe précédente soit sortie et que la salle
                  se configure</DoiText
                >
                <DoiText :textColor="3" :fontFamily="4" :fontSize="3"
                  ><DoiText
                    type="span"
                    :textColor="0"
                    :fontFamily="5"
                    :fontSize="3"
                    >4 - </DoiText
                  >Dès qu'on me donne l'autorisation, je rentre sur le
                  terrain</DoiText
                >
                <DoiText :textColor="3" :fontFamily="4" :fontSize="3"
                  ><DoiText
                    type="span"
                    :textColor="0"
                    :fontFamily="5"
                    :fontSize="3"
                    >5 - </DoiText
                  >Je configure les équipes et je lance la partie</DoiText
                >
                <DoiText :textColor="3" :fontFamily="4" :fontSize="3"
                  ><DoiText
                    type="span"
                    :textColor="0"
                    :fontFamily="5"
                    :fontSize="3"
                    >6 - </DoiText
                  >Pendant mon match, je marque les temps forts (buts, fautes,
                  ....)</DoiText
                >
                <DoiText :textColor="3" :fontFamily="4" :fontSize="3"
                  ><DoiText
                    type="span"
                    :textColor="0"
                    :fontFamily="5"
                    :fontSize="3"
                    >7 - </DoiText
                  >Je quitte la zone de jeu rapidement lorsque mon match est
                  terminé</DoiText
                >
                <DoiText :textColor="3" :fontFamily="4" :fontSize="3"
                  ><DoiText
                    type="span"
                    :textColor="0"
                    :fontFamily="5"
                    :fontSize="3"
                    >8 - </DoiText
                  >Je retrouve le score et le replay de ma partie dans mon
                  espace perso</DoiText
                >
              </div>
            </div>
          </div>
          <div
            class="flex justify-between mt-4 cursor-pointer tab"
            @click="contact = !contact"
          >
            <div class="flex flex-col justify-center">
              <div class="flex flex-row">
                <DoiText
                  :textColor="3"
                  :fontSize="3"
                  :fontFamily="6"
                  class="accordion-title"
                  >EN CAS DE PROBLÈME, CONTACTEZ-NOUS</DoiText
                >
              </div>
            </div>
            <div class="bg-fa-red flex items-center text-white p-2 w-12 h-12">
              <span class="material-icons text-3xl" v-if="!contact">
                keyboard_arrow_down
              </span>
              <span class="material-icons text-3xl" v-if="contact">
                keyboard_arrow_up
              </span>
            </div>
          </div>
          <div v-show="contact" class="tab-content">
            <div class="flex flex-col py-4 pl-2">
              <DoiText :textColor="3" :fontFamily="4" :fontSize="3"
                >Pour tout problème d’accès à la Fit Arena, veuillez appeler le
                numéro suivant : {{ fitArenaInfos }}.</DoiText
              >
            </div>
          </div>
        </div>
      </template>
    </Modal>
    <Modal
      title="SUPPRESSION RÉSERVATION"
      :open="openSuppression"
      :keepratio="true"
      :displayHeader="true"
      size="xs"
      id="modal"
    >
      <template slot="modal_content">
        <div class="p-4 overflow-hidden text-center">
          <DoiText :textColor="1" :fontSize="3" :fontFamily="5">{{
            message
          }}</DoiText>
        </div>
      </template>
      <template slot="modal_footer">
        <DoiButton @click="refresh()" :bgHover="1" class="mx-auto w-4/12"
          >OK</DoiButton
        >
      </template>
    </Modal>
    <Modal
      title="SUPPRESSION PARTICIPANT"
      :open="openSuppressionParticipant"
      :keepratio="true"
      :displayHeader="true"
      size="xs"
      id="modal"
      @close-modal="openSuppressionParticipant = false"
    >
      <template slot="modal_content">
        <div class="p-4 overflow-hidden text-center">
          <DoiText :textColor="1" :fontSize="3" :fontFamily="5"
            >Êtes-vous sûr de vouloir supprimer {{ participantName }} de la
            liste des participants ? Attention, il ne pourra plus rejoindre
            cette réservation.</DoiText
          >
        </div>
      </template>
      <template slot="modal_footer">
        <DoiButton
          @click="deleteParticipant()"
          :bgHover="1"
          :fontFamily="5"
          class="mx-auto w-4/12 mb-4"
          >SUPPRIMER</DoiButton
        >
        <DoiButton
          @click="openSuppressionParticipant = false"
          :bgColor="2"
          :bgHover="3"
          :fontFamily="5"
          class="mx-auto w-4/12 mb-4"
          >ANNULER</DoiButton
        >
      </template>
    </Modal>
    <Modal
      title="ANNULATION RÉSERVATION"
      :open="annulationReservation"
      :keepratio="true"
      :displayHeader="true"
      size="s"
      id="modal"
      @close-modal="annulationReservation = false"
    >
      <template slot="modal_content">
        <div class="p-4 overflow-hidden text-center">
          <DoiText :textColor="1" :fontSize="3" :fontFamily="5"
            >Vous êtes sur le point d'annuler votre réservation. Souhaitez-vous
            poursuivre ?</DoiText
          >
        </div>
      </template>
      <template slot="modal_footer">
        <DoiButton
          @click="cancelReservation()"
          :bgHover="1"
          :fontFamily="5"
          :disabled="isDisabled"
          class="mx-auto w-4/12 mb-4"
          >OUI
          <DoiSpinner :show="showSpinner" :color="1" class="ml-3" />
        </DoiButton>
        <DoiButton
          @click="annulationReservation = false"
          :bgColor="2"
          :bgHover="3"
          :fontFamily="5"
          :disabled="isDisabled"
          class="mx-auto w-4/12 mb-4"
          >NON
        </DoiButton>
      </template>
    </Modal>
  </div>
</template>

<script>
import BookingGamer from "./BookingGamer";
import Modal from "@/components/molecules/Modal";
import BookingItemDetailParticipant from "@/views/booking/BookingItemDetailParticipant";
import BookingItemDetailFamily from "@/views/booking/BookingItemDetailFamily";
import {
  generateQrCode,
  generateQrCodeV2,
  deleteReservation,
  deleteReservationV2,
} from "@/api/BookingService";
import { generateImageUrlV2 } from "@/utils/imgUrlBuilder";
import { mapActions, mapGetters } from "vuex";
import { getContactsV2 } from "@/api/ContactService";

import dayjs from "dayjs";

export default {
  name: "BookingItemDetail",
  data() {
    return {
      contactId: null,
      showQrCode: false,
      qrCode: "",
      codePin: "",
      fitArenaInfos: "",
      howTo: false,
      openInvitGamer: false,
      qrCodeSectionOpen: true,
      imgSize: 200,
      uuidReservation: "",
      openSuppression: false,
      openSuppressionParticipant: false,
      message: "",
      date: 0,
      contact: false,
      participantName: "",
      participantIdContact: Number,
      annulationReservation: false,
      showSpinner: false,
    };
  },
  components: {
    BookingGamer,
    Modal,
    BookingItemDetailFamily,
    BookingItemDetailParticipant,
  },
  props: {
    bookingDetail: {
      type: Object,
      default: () => {},
    },
  },
  async mounted() {
    await this.generateContactV2(this.bookingDetail.participants);
  },
  computed: {
    ...mapGetters("AuthModule", ["get_userIdV2"]),
    isDisabled() {
      return this.showSpinner;
    },
    now_timestamp() {
      return Math.round(Date.now() / 1000);
    },
    userAvatarBorderClass() {
      let style = "border-4 rounded-full ";
      if (this.user.response === 2) return `${style} border-green`;
      if (this.user.response === 1) return `${style} border-grey`;
      if (this.user.response === 3) return `${style} border-red`;
    },
    participants() {
      return this.bookingDetail.participants;
    },
    user() {
      return this.participants.find(
        (participant) => participant.idUser === this.userIdUtilisateur
      );
    },
    familles() {
      return this.participants.filter(
        (participant) => participant.belongToFamily
      );
    },
    invites() {
      return this.participants.filter(
        (participant) =>
          !participant.belongToFamily &&
          participant.idUser !== this.userIdUtilisateur
      );
    },
    isOrganizer() {
      const orga = this.participants.find(
        (participant) =>
          participant.organizer === true &&
          participant.idUser === this.userIdUtilisateur
      );
      if (orga) return true;
      else return false;
    },
    getUserContactId() {
      return this.$store.getters["UserModule/get_contact_id"];
    },
    userIdUtilisateur() {
      return this.$store.getters["UserModule/get_user_id"];
    },
    userAvatar() {
      return this.user.avatar
        ? generateImageUrlV2(this.user.avatar)
        : require("@/assets/img/no-avatar.png");
    },
    modifyTimeResponse() {
      return (
        Math.floor(Date.now() / 1000) -
        dayjs(this.bookingDetail.startDate).unix()
      );
    },
  },
  methods: {
    ...mapActions("ReservationListModule", ["deleteParticipantInResa"]),
    inviter() {
      this.openInvitGamer = true;
    },
    closeModal() {
      this.openInvitGamer = false;
    },
    onReload() {
      this.$emit("onReload");
    },
    idContactParticipant(idContact, name) {
      this.participantName = name;
      this.participantIdContact = idContact;
      this.openSuppressionParticipant = true;
    },
    deleteParticipant() {
      this.deleteParticipantInResa({
        idResa: this.bookingDetail.uuidReservation,
        idInvite: this.participantIdContact,
      });
      this.openSuppressionParticipant = false;
    },
    async generateQrCode(userId) {
      const data = await generateQrCodeV2(
        parseInt(userId),
        this.bookingDetail.uuidReservation
      );
      this.fitArenaInfos = data.sFitArenaInformation;
      this.qrCode = data.qrcode;
      this.codePin = data.pinCode;
      this.showQrCode = true;
    },
    async generateQrCodeAnimateur(userId) {
      const data = await generateQrCodeV2(
        parseInt(userId),
        this.bookingDetail.uuidReservation,
        "animateur"
      );
      this.qrCode = data.qrcode;
      this.codePin = data.pinCode;
      this.fitArenaInfos = data.sFitArenaInformation;
      this.showQrCode = true;
    },
    async generateQrCodeAdherent(userId) {
      const data = await generateQrCodeV2(
        parseInt(userId),
        this.bookingDetail.uuidReservation,
        "seance"
      );
      this.qrCode = data.qrcode;
      this.codePin = data.pinCode;
      this.fitArenaInfos = data.sFitArenaInformation;
      this.showQrCode = true;
    },
    onChangeQrCode($event) {
      this.imgSize = parseInt($event.target.value);
    },
    async cancelReservation() {
      this.showSpinner = true;
      this.uuidReservation = this.bookingDetail.uuidReservation;
      // besoin de faire appels v1 et v2 pour actualiser les statuts des réservations
      // en sachant que l'erreur 400 de l'appel v2 est catchée dans NetworkConfig
      const { responseV2 } = await deleteReservationV2(this.uuidReservation); // WS V2
      const res = await deleteReservation(this.uuidReservation); // WS V1
      this.openSuppression = true;
      this.annulationReservation = false;

      if (res.succes) this.message = "Votre réservation a bien été annulée.";
      else this.message = "Votre réservation n'a pas pu être annulée.";
      this.showSpinner = false;
    },
    refresh() {
      this.openSuppression = false;
      this.$router.go();
    },
    async generateContactV2(participants) {
      const response = await getContactsV2();
      participants.forEach((participant) => {
        // si c'est un membre de ma famille mais pas l'organisateur donc pas moi, je récupère son idV2 pour récupérer son QrCode
        if (participant.famille && !participant.organizer) {
          const i = response?.find((r) => participant.pseudo === r.identifiant);
          participant = Object.defineProperties(participant, {
            idV2: {
              value: i.id,
              writable: true,
            },
          });
        }
      });
    },
  },
  watch: {
    familles: {
      async handler() {
        await this.generateContactV2(this.participants);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.tab {
  border-bottom: solid 2px $doi--color-color0;
  border-left: solid 1px #e5e5e5;
  border-top: solid 1px #e5e5e5;
  padding-left: 15px;
}

.border-green {
  border-color: $doi--color-color2;
}
.border-grey {
  border-color: $doi--color-color4;
}
.border-red {
  border-color: $doi--color-color0;
}

.bg-red {
  background-color: $doi--color-color0;
}
.bg-grey {
  background-color: $doi--color-color4;
}

.info {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

@media (max-width: 650px) {
  .accordion-title {
    font-size: $doi--text-font-size2;
  }
}
</style>
