<template>
  <div class="flex flex-col h-full">
    <div>
      <Navigation :floating="false" />
    </div>
    <div class="flex flex-col flex-1 mt-8">
      <slot></slot>
    </div>
    <div>
      <Footer :displaySubHeader="displaySubFooter" />
    </div>
  </div>
</template>

<script>
import Footer from "@/components/organisms/Footer";
import Navigation from "@/components/organisms/Navigation";

export default {
  name: "containerApp",
  components: {
    Footer,
    Navigation,
  },
  computed: {
    displaySubFooter() {
      return this.$route.meta.displaySubFooter;
    },
  },
};
</script>
