<template>
  <div>
    <div>
      <FitArenaHeader
        v-if="fitArena.urlBandeau && isLoaded"
        :fitArena="fitArena"
        @onReload="onReload++"
      />
    </div>
    <section>
      <SportSelector :fitArena="fitArena" :sportListProps="fitArena.sports" />
    </section>
    <section>
      <Planning :onReload="onReload" />
    </section>
    <!-- <section>
      <div
        class="flex items-center py-4 pl-4 container max-w-6xl mx-auto"
        id="actualites"
      >
        <DoiHeader
          v-if="this.$route.params.fitArenaName == 'padel-park'"
          :level="2"
          :fontFamily="0"
          class="header-font"
          >ACTUALITÉS AU PADEL PARK</DoiHeader
        >
        <DoiHeader v-else :level="2" :fontFamily="0" class="header-font"
          >ACTUALITÉS</DoiHeader
        >
      </div>
      <ArticleList :fitArenaName="fitArenaName" />
    </section> -->
  </div>
</template>

<script>
import FitArenaHeader from "@/views/fitarena/FitArenaHeader";
import Planning from "@/views/fitarena/Planning";
import SportSelector from "@/views/fitarena/SportSelector";
// import ArticleList from "@/views/fitarena/ArticleList";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "FitArena",
  data() {
    return {
      onReload: 0,
      isLoaded: false,
    };
  },
  components: {
    FitArenaHeader,
    Planning,
    SportSelector,
    // ArticleList,
  },
  async mounted() {
    setTimeout(() => {
      this.retrieveFitArena2(this.fitArenaName);
      this.isLoaded = true;
    }, 1000);
  },
  computed: {
    ...mapGetters("FitArenaModule", ["fitArena", "idFitArena"]),
    fitArenaName() {
      return this.$route.params.fitArenaName;
    },
  },
  methods: {
    ...mapActions("FitArenaModule", ["retrieveFitArena", "retrieveFitArena2"]),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.header-font {
  font-size: $doi--header4;
  letter-spacing: 1px;
}
</style>
