<template>
  <div class="min-h-screen flex flex-col">
    <div class="flex flex-1 flex-col">
      <div class="title-long-border"></div>
      <div
        class="mx-auto lg:max-w-2k flex justify-center p-8 lg:p-20 flex-col items-center w-full lg:w-5/12"
      >
        <div class="py-8 flex flex-col items-center">
          <div class="flex flex-col items-center justify-center">
            <DoiHeader :level="4" :fontFamily="1" :headerColor="0" class="mb-4"
              >JE M'IDENTIFIE</DoiHeader
            >
            <DoiHeader
              :level="4"
              :fontFamily="0"
              :headerColor="2"
              class="tracking-widest text-center mt-2 mb-7"
              >BOOK, PLAY, SHARE !</DoiHeader
            >
          </div>
        </div>

        <!--<div v-if="!isCordova()" class="flex lg:flex-row flex-col mb-4">
          <DoiButton :bgColor="4" :textColor="1" class="connect-btn lg:mb-0" @click="socialNetwork('facebook')"><img class="pr-4 w-11" src="@/assets/img/connection/facebook.png" alt="Logo Facebook" />Connexion avec Facebook</DoiButton>
        </div>-->
        <!-- <DoiButton
          :bgColor="4"
          :textColor="1"
          class="connect-btn lg:mb-0"
          @click="socialNetwork('google')"
          ><img
            class="pr-4 w-11"
            src="@/assets/img/connection/google.png"
            alt="Logo Google"
          />Connexion avec Google</DoiButton
        >
        <DoiButton
          :bgColor="4"
          :textColor="1"
          class="connect-btn lg:mb-0"
          @click="socialNetwork('apple')"
          ><img
            class="pr-4 w-11"
            src="@/assets/img/connection/apple.png"
            alt="Logo Apple"
          />Connexion avec Apple</DoiButton
        > -->

        <div class="w-full">
          <form @submit.prevent="submit">
            <div class="mb-6">
              <DoiInput
                v-model.trim="$v.email.$model"
                :error="$v.email.$error"
                placeholder="Votre email *"
                ref="username"
                name="username"
                autocomplete="username"
              ></DoiInput>
              <DoiText
                italic
                :textColor="0"
                :fontSize="1"
                v-if="!$v.email.required && $v.email.$error"
                >Ce champ est requis</DoiText
              >
            </div>
            <div class="mb-4">
              <DoiInput
                type="password"
                v-model="$v.password.$model"
                :error="$v.password.$error"
                placeholder="Votre mot de passe *"
                autocomplete="current-password"
                ref="password"
                name="password"
              ></DoiInput>
              <DoiText
                italic
                :textColor="0"
                :fontSize="1"
                v-if="!$v.password.required && $v.password.$error"
                >Ce champ est requis</DoiText
              >
            </div>
            <div class="flex justify-center mt-10">
              <button
                id="loginValidation"
                class="button red"
                type="submit"
                :disabled="isDisabled"
              >
                <DoiText>VALIDER</DoiText>
                <DoiSpinner :show="showSpinner" :color="1" class="ml-3" />
              </button>
            </div>
            <div class="text-center mt-4 text-xs">
              <DoiLink link="/forgot-password">Mot de passe oublié ?</DoiLink>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="bg-gray">
      <div
        class="mx-auto lg:max-w-2k flex justify-center p-8 lg:p-10 flex-col items-center w-full lg:w-5/12"
      >
        <DoiHeader
          :level="5"
          :fontFamily="1"
          :headerColor="0"
          class="md:title-border mb-4"
          >Je n'ai pas de compte</DoiHeader
        >
        <div class="flex justify-center mt-4">
          <DoiButton link="/signup" :bgColor="2">M'INSCRIRE</DoiButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { login, accountConfirmation } from "@/api/AuthService";

export default {
  name: "SignIn",
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Page d'authentification du site fit-arena.fr. Réservations des salles multisports connectée, intelligente et autonome. Partage d'expériences sportives et réservation en ligne",
      },
    ],
    link: [{ rel: "canonical", href: "https://www.fit-arena.fr/signin" }],
  },
  data() {
    return {
      email: "",
      password: "",
      open: false,
      errorMessage: "",
      showSpinner: false,
      sJeton: "",
      nIdGrandPublic: "",
    };
  },
  validations: {
    password: { required },
    email: { required },
  },
  mounted() {
    if (this.$route.query.token)
      this.$store.dispatch("AuthModule/SET_TOKEN", this.$route.query.token);
    if (this.$route.query.uuidUser) this.accountConfirmationV2();
    if (document.body.className == "no-scroll")
      document.body.classList.remove("no-scroll");
  },
  computed: {
    isDisabled() {
      return this.showSpinner;
    },
    lastRoute() {
      return this.$store.getters["RouterHistoryModule/lastRoute"];
    },
  },
  methods: {
    async accountConfirmationV2() {
      const res = await accountConfirmation(
        this.$route.query.uuidUser,
        this.$route.query.uuidConfirmation
      );

      if (res.succes === false) {
        const msg = "Lien déjà utilisé";
        if (screen.width > 640) {
          this.$toast.error(msg);
        } else {
          this.$toast.error(msg, {
            position: "top",
          });
        }
      } else {
        const msg =
          "Votre compte a bien été activé, vous pouvez désormais vous connecter.";

        if (screen.width > 640) {
          this.$toast.success(msg);
        } else {
          this.$toast.success(msg, {
            position: "top",
          });
        }
      }
    },
    isCordova() {
      return window.navigator.userAgent.includes("cordova");
    },
    async socialNetwork(social) {
      window.open(
        `${process.env.VUE_APP_BACK}admin/authentification/reseau-sociaux?social=${social}`
      );
    },
    submit() {
      this.email = this.$refs.username.$el.lastChild.value;
      this.password = this.$refs.password.$el.lastChild.value;

      this.showSpinner = true;
      this.$v.$touch();
      if (this.$v.$invalid) this.showSpinner = false;
      else this.loginForm();
    },
    getToken() {
      const token = this.$store.getters["AuthModule/get_token_2"];
      if (token !== null && token !== "") {
        const invitation = this.$store.getters["InvitationModule/invitation"];
        if (this.lastRoute.name === "fitarena") {
          this.$router.go(-1);
        } else if (invitation.isInvited) {
          const id = invitation.id_reservation;
          const jeton = invitation.jeton_invitation;
          this.$router.push(`/invitation/${id}/${jeton}`);
        } else {
          this.$router.push("/profile");
        }
      }
    },
    async loginForm() {
      try {
        const response = await login({
          email: this.email,
          password: this.password,
        });
        if (response) {
          this.$store.dispatch("AuthModule/SET_TOKEN", response.token);
          setTimeout(() => {
            this.getToken();
          }, 1000);
          if (this.lastRoute.path.includes("invitation"))
            this.$router.push(this.lastRoute.path);
        }
      } catch (error) {
        this.showSpinner = false;
      }
      this.showSpinner = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.connect-btn {
  border: 1px solid $doi--button-color2;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.bg-gray {
  background: rgb(232, 232, 232);
}

.title-long-border {
  content: "";
  height: 2px;
  width: calc(50% - 250px);
  background-color: $doi--color-color0;
  position: relative;
  top: 130px;
}

.title-border {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.title-border::before {
  content: "";
  width: 400px;
  height: 1px;
  display: block;
  background-color: rgb(207, 207, 207);
  margin-right: 25px;
}
.title-border::after {
  content: "";
  width: 400px;
  height: 1px;
  display: block;
  background-color: rgb(207, 207, 207);
  margin-left: 25px;
}
</style>
