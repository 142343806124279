import Network from "@/utils/Network";

const url1 = process.env.VUE_APP_API_URL;
const url2 = process.env.VUE_APP_API_URL_V2;

const getUserInfo = (userId) => {
  return Network.execute({
    method: "GET",
    url: url2 + `users/${userId}`,
  });
};

const setUserInfo = (payload) => {
  return Network.execute({
    method: "POST",
    url: url1 + "grand-public/compte/modifie",
    payload: payload,
    secure: true,
  });
};

const setUserAvatar = (payload) => {
  return Network.execute({
    method: "POST",
    url: url2 + "user/avatar",
    payload: payload,
    secure: true,
  });
};

const getUserDiscount = (id, idFitArena) => {
  return Network.execute({
    method: "GET",
    url: url2 + `user/${id}/discount/fitarena/${idFitArena}`,
    secure: true,
  });
};

const removeUser = (id) => {
  return Network.execute({
    method: "DELETE",
    url: url2 + `soft/delete/user/${id}`,
    secure: true,
  });
};

const getUserReservations = (id) => {
  return Network.execute({
    method: "GET",
    url: url2 + `user/${id}/liste_reservations`,
    secure: true,
  });
};

export {
  getUserInfo,
  setUserInfo,
  getUserDiscount,
  setUserAvatar,
  removeUser,
  getUserReservations,
};
