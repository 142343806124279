<template>
  <div>
    <div v-if="bookingList.length > 0">
      <div
        v-for="(booking, index) in bookingList"
        :key="booking.uuidReservation"
      >
        <BookingItem
          :booking="booking"
          :firstItem="index === 0"
          :firstItemReservation="
            booking.uuidReservation === idFirstReservationPanier
          "
          :showReservation="showReservation"
        />
      </div>
    </div>
    <div v-else class="flex justify-center mb-8">
      <DoiSpinner v-if="isLoading" :spinnerSize="1" />
      <div v-else>
        <DoiText :textColor="3" :fontSize="4" class="mb-2 text-center"
          >Vous n'avez aucune réservation à venir</DoiText
        >
        <DoiButton link="/#fit-arena-list">Réserver un créneau</DoiButton>
      </div>
    </div>
  </div>
</template>

<script>
import BookingItem from "./BookingItem";

export default {
  name: "BookingList",
  data() {
    return {
      isLoaded: true,
    };
  },
  components: { BookingItem },
  props: {
    showReservation: {
      required: false,
    },
    typeReservation: {
      type: String,
      default: "grand_public",
    },
    bookingList: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    // filterTypeResa: {
    //   type: Array,
    //   default: [],
    // },
  },
  computed: {
    idFirstReservationPanier() {
      return this.$store.getters[
        "ReservationListModule/idFirstReservationPanier"
      ];
    },
  },
};
</script>
