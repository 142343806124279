<template>
  <ContactInfo
    :contact="participant"
    :class="{ 'border-t-2 border-gray pt-2': nIndex !== 0 }"
  >
    <div
      v-if="bookingDetail.type_resa === 'collectivite'"
      class="flex flex-col justify-end items-end"
    >
      <img
        v-if="!participant.reponse || participant.reponse === 0"
        src="@/assets/img/reservations/switch-peut-etre.png"
        alt="Hésitation sur l'invitation"
      />
      <img
        v-if="participant.reponse === 1"
        src="@/assets/img/reservations/switch-refuse.png"
        alt="Refus de l'invitation"
      />
      <img
        v-if="participant.reponse === 2"
        @click="generateQrCode(participant.id_contact)"
        src="@/assets/img/reservations/switch-accepte.png"
        alt="Acceptation de l'invitation"
      />
    </div>
    <div
      v-if="
        bookingDetail.type_resa !== 'adherent' &&
        bookingDetail.type_resa !== 'collectivite'
      "
      class="flex flex-col justify-end items-end"
    >
      <button
        class="pointer-events-none button sm w-24 h-8 grey"
        v-if="!participant.response || participant.response === 1"
      >
        <span>PEUT-ÊTRE</span>
      </button>
      <button
        v-if="participant.response === 2"
        @click="generateQrCode(participant.id_contact)"
        class="pointer-events-none button sm h-8 w-24 green"
      >
        <span>ACCEPTÉ</span>
      </button>
      <button
        class="pointer-events-none button sm w-24 h-8 red"
        v-if="participant.response === 3"
      >
        <span>ABSENT</span>
      </button>
    </div>
    <a
      v-if="isOrganizer && bookingDetail.annulationReservation == true"
      class="flex justify-end cursor-pointer underline"
      @click="
        emitIdContactParticipant(participant.id_contact, participant.pseudo)
      "
      >Suppr.</a
    >
  </ContactInfo>
</template>

<script>
import ContactInfo from "@/components/molecules/ContactInfo.vue";

export default {
  name: "BookingItemDetailParticipant",
  props: {
    participant: Object,
    isOrganizer: Boolean,
    bookingDetail: {
      type: Object,
      default: () => {},
    },
    nIndex: Number,
  },
  components: { ContactInfo },
  methods: {
    generateQrCode(idContact) {
      this.$emit("generateQrCode", idContact);
    },
    emitIdContactParticipant(idContact, pseudo) {
      this.$emit("idContactParticipant", idContact, pseudo);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

a {
  font-size: $doi--text-font-size1;
  font-family: $doi--text-font-family4;
}

button {
  font-size: 0.7rem;
}
</style>
